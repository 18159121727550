var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{key:_vm.componentKey},[_c('v-card-title',{staticClass:"h1 justify-center"},[_vm._v(_vm._s(_vm.action)+" Selecionar Etapa Periférico")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"ip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.servicos,"item-text":"descricao","return-object":"","label":"Serviço","error-messages":errors,"prepend-icon":"mdi-face-agent","required":""},model:{value:(_vm.servicosCadastrados),callback:function ($$v) {_vm.servicosCadastrados=$$v},expression:"servicosCadastrados"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"ip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Etapa Serviço","items":_vm.etapasServico,"item-text":"nome","item-value":"public_id","error-messages":errors,"prepend-icon":"mdi-car-wash","required":""},model:{value:(_vm.etapaPeriferico.etapa_servico),callback:function ($$v) {_vm.$set(_vm.etapaPeriferico, "etapa_servico", $$v)},expression:"etapaPeriferico.etapa_servico"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"ip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.perifericos,"item-text":"ip","item-value":"public_id","label":"Periférico","error-messages":errors,"prepend-icon":"mdi-boom-gate-outline","required":""},on:{"click":function($event){return _vm.buscarPeriferico()}},model:{value:(_vm.etapaPeriferico.periferico),callback:function ($$v) {_vm.$set(_vm.etapaPeriferico, "periferico", $$v)},expression:"etapaPeriferico.periferico"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"ip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"item-text":"comando","item-value":"value","items":_vm.comandos,"label":"Comando","error-messages":errors,"prepend-icon":"mdi-button-pointer","required":""},model:{value:(_vm.etapaPeriferico.comando),callback:function ($$v) {_vm.$set(_vm.etapaPeriferico, "comando", $$v)},expression:"etapaPeriferico.comando"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-spacer'),_c('buttons-cadastro',{attrs:{"disabled":invalid,"value":_vm.action === 'Editar' ? 'Atualizar' : 'Salvar'},on:{"save":_vm.save,"close":function($event){return _vm.$emit('addedEtapaPeriferico')}}})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }