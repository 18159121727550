<template>
  <v-container :key="componentKey">
    <v-card-title class="h1 justify-center"
      >{{ action }} Selecionar Etapa Periférico</v-card-title
    >
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form>
        <v-row justify="center">
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" name="ip" rules="required">
              <v-select
                v-model="servicosCadastrados"
                :items="servicos"
                item-text="descricao"
                return-object
                label="Serviço"
                :error-messages="errors"
                prepend-icon="mdi-face-agent"
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" name="ip" rules="required">
              <v-select
                label="Etapa Serviço"
                v-model="etapaPeriferico.etapa_servico"
                :items="etapasServico"
                item-text="nome"
                item-value="public_id"
                :error-messages="errors"
                prepend-icon="mdi-car-wash"
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" name="ip" rules="required">
              <v-select
                v-model="etapaPeriferico.periferico"
                :items="perifericos"
                item-text="ip"
                item-value="public_id"
                label="Periférico"
                :error-messages="errors"
                prepend-icon="mdi-boom-gate-outline"
                required
                @click="buscarPeriferico()"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <validation-provider v-slot="{ errors }" name="ip" rules="required">
              <v-select
                v-model="etapaPeriferico.comando"
                item-text="comando"
                item-value="value"
                :items="comandos"
                label="Comando"
                :error-messages="errors"
                prepend-icon="mdi-button-pointer"
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <v-card-actions>
              <v-spacer />
              <buttons-cadastro
                :disabled="invalid"
                :value="action === 'Editar' ? 'Atualizar' : 'Salvar'"
                @save="save"
                @close="$emit('addedEtapaPeriferico')"
              />
            </v-card-actions>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>


<script>
import { mapActions, mapState } from 'vuex'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'

export default {
  components: { ButtonsCadastro },
  name: 'EditarEtapaPeriferico',

  data: function () {
    return {
      componentKey: 0,
      servicosCadastrados: [],
      etapas: [],
      etapaDoServico: [],
      etapaPeriferico: {},
      comandos: [
        { comando: 'ADD CAMERA', value: 'AC' },
        { comando: 'REMOVE CAMERA', value: 'RC' },
        { comando: 'OPEN BARRIER', value: 'OB' },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('patio', ['servicos', 'perifericos']),

    etapasServico() {
      this.servicos.filter((servico) => {
        if (servico.id == this.servicosCadastrados.id) {
          this.etapas = servico.etapas
        }
      })
      return this.etapas
    },
  },

  async created() {
    await this.getServicos({ proprietario: this.empresaAtual.public_id })
  },

  methods: {
    ...mapActions('patio', [
      'getServicos',
      'getPeriferico',
      'addEtapaPeriferico',
    ]),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    async buscarPeriferico() {
      await this.getPeriferico({ proprietario: this.empresaAtual.public_id })
    },

    forceRerender() {
      this.componentKey += 1;
    },

    async save() {
      try {
        let etapaPeriferico = await this.addEtapaPeriferico({
          ...this.etapaPeriferico,
          //proprietario: this.empresaAtual.public_id,
        })
        this.$emit('addedEtapaPeriferico', etapaPeriferico)
        this.successMessage('Etapa do Periférico salva com sucesso!')
        this.etapaPeriferico = {}
        this.servicosCadastrados = []
        this.forceRerender()
      } catch (error) {
        this.errorMessage(error.response.data)
      }
    },
  },
}
</script>
<style></style>
