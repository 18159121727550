<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Etapa Periférico">
          <editar-etapa-periferico
            v-if="showForm"
            :perifericoEtapaEdit="perifericoEtapaEdit"
            @addedEtapaPeriferico="
              showForm = true
              perifericoEtapaEdit = {}
            "
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditarEtapaPeriferico from '@/components/patio/Perifericos/EditarEtapaPeriferico.vue'

export default {
  components: {
    EditarEtapaPeriferico,
  },
  data() {
    return {
      showForm: true,
      perifericoEtapaEdit: {},
      page: {
        title: 'Gerenciamento de Periféricos',
      },
      breadcrumbs: [
        {
          text: 'Pátios',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Etapa Periférico',
          disabled: false,
        },
      ],
    }
  },
  methods: {},
}
</script>